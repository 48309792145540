<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/formas-entrega"
  >
    <div v-if="mode == 'detail'">
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="nomeforma"
        :sortDesc="false"
        ref="datatable"
        search
        :limitPage="[10, 25, 50, 100, 200]"
      >
        <template v-slot:custo_transporte="{ col }">
          {{ col | formatMoney }}
        </template>
        <template v-slot:custo_percentual_seguro="{ col }">
          {{ col | formatMoney }}
        </template>
        <template v-slot:total_pedido_inicial="{ col }">
          {{ col | formatMoney }}
        </template>
        <template v-slot:total_pedido_final="{ col }">
          {{ col | formatMoney }}
        </template>
      </DataTable>
    </div>
    <div v-else-if="mode != 'simulation'">
      <h4 class="text-center">Dados básicos</h4>
      <LineDivider></LineDivider>
      <v-row justify="center" v-if="mode != 'edit'">
        <FormRadioGroup
          v-model="form.tipo"
          inline
          :radios="formasEntregas.filter(f => f.show)"
        />
      </v-row>
      <v-window v-model="form.tipo">
        <v-window-item
          v-for="(f, i) in formasEntregas.filter(f => f.show)"
          :key="i"
          :value="f.value"
        >
          <FormaEntregaFormDelivery
            v-if="f.value == 'DELIVERY'"
            :calendarios="calendarios"
            :data="form"
            @update="updateValues"
          />
          <FormaEntregaFormRetirada
            v-if="f.value == 'RETIRADA'"
            :calendarios="calendarios"
            :data="form"
            @update="updateValues"
          />
          <FormaEntregaFormTransporte
            v-if="f.value == 'TRANSPORTE'"
            :calendarios="calendarios"
            :data="form"
            @update="updateValues"
          />
          <FormaEntregaFormCorreios
            v-if="f.value == 'CORREIOS'"
            :data="form"
            @update="updateValues"
          />
          <FormaEntregaFormFrenet
            v-if="f.value == 'FRENET'"
            :data="form"
            @update="updateValues"
          />
          <FormaEntregaFormCliente
            v-if="f.value == 'BASEADA_CLIENTE'"
            :calendarios="calendarios"
            :data="form"
            @update="updateValues"
          />
        </v-window-item>
      </v-window>
    </div>
    <div v-else>
      <FormaEntregaFormSimulacao ref="formSimulacao" />
    </div>
    <template v-slot:buttons>
      <FormButton
        text="Salvar"
        submit
        type="submissao"
        v-if="mode != 'simulation' && mode != 'detail'"
      />
      <FormButton
        v-else-if="mode != 'detail'"
        text="Simular"
        type="primario"
        @click="onSubmitSimulation"
      />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import DataTable from '@/components/DataTable'
import FormaEntregaFormDelivery from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormDelivery'
import FormaEntregaFormRetirada from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormRetirada'
import FormaEntregaFormCliente from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormCliente'
import FormaEntregaFormCorreios from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormCorreios'
import FormaEntregaFormFrenet from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormFrenet'
import FormaEntregaFormTransporte from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormTransporte'
import FormaEntregaFormSimulacao from '@/pages/cadastro/formasentrega/forms/FormaEntregaFormSimulacao'
import * as _ from 'lodash'
import { mapGetters } from 'vuex'
import {
  FORMA_ENTREGA_CREATE,
  FORMA_ENTREGA_UPDATE,
  FORMA_ENTREGA_LOAD,
  FORMASENTREGAS,
  FORMA_ENTREGA_CALENDARIO_LIST,
  FORMA_ENTREGA_FORNECEDOR,
  FORMA_ENTREGA_FORMAS_LIST
} from '@/store/actions/formasEntrega'

export default {
  name: 'FormaEntregaForm',
  components: {
    SidePopup,
    LineDivider,
    FormButton,
    FormRadioGroup,
    DataTable,
    FormaEntregaFormDelivery,
    FormaEntregaFormRetirada,
    FormaEntregaFormCliente,
    FormaEntregaFormCorreios,
    FormaEntregaFormFrenet,
    FormaEntregaFormTransporte,
    FormaEntregaFormSimulacao
  },
  data: () => ({
    mode: 'edit',
    isLoading: false,
    headers: [
      {
        text: 'Descrição da forma de transporte',
        value: 'descricao_forma_transporte'
      },
      { text: 'CEP Inicial', value: 'cep_inicial' },
      { text: 'CEP Final', value: 'cep_final' },
      { text: 'Custo transporte', value: 'custo_transporte' },
      { text: 'Custo percentual do seguro', value: 'custo_percentual_seguro' },
      { text: 'Peso Inicial', value: 'peso_inicial' },
      { text: 'Peso Final', value: 'peso_final' },
      { text: 'Quantidade de dias de preparo', value: 'quant_dias_preparo' },
      { text: 'Quantidade de dias de entrega', value: 'quant_dias_entrega' },
      { text: 'Total inicial do pedido', value: 'total_pedido_inicial' },
      { text: 'Total final do pedido', value: 'total_pedido_final' }
    ],
    formasEntrega: Object.keys(FORMASENTREGAS).map(chave => ({
      label: FORMASENTREGAS[chave],
      value: chave
    })),
    formSimulacao: {
      tipo: ''
    },
    form: {
      nome: '',
      nomeforma: '',
      erpTransportadora: null,
      tipo: null,
      idfornecedor: null,
      idcalendarioentrega: null,
      permiteAgendamento: false,
      idformaentrega: null,
      taxaConveniencia: null,
      taxaExtraPorMetro: null,
      taxaColeta: null,
      correios: {
        usuario: '',
        senha: '',
        cartaoPostagem: '',
        cepOrigem: '',
        acrescimoTaxa: 0,
        diasSeparacao: 1,
        metodoCalculo: 'SOMA_CUSTO_POR_PRODUTO',
        valorDeclarado: 'NAO_DECLARAR_VALOR',
        opcoes: {
          servicos: []
        }
      },
      currentFile: null,
      encoding: 'utf8',
      frenet: {
        token: '',
        cepOrigem: '',
        acrescimoTaxa: 0,
        diasSeparacao: 1,
        transportadoras: [],
        permiteRestricoesFrenet: false
      },
      baseadaCliente: {
        idsegmentocliente: null,
        formas: []
      }
    },
    calendarios: []
  }),
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/formas-entrega')
      }
    },
    onSubmitSimulation() {
      this.$refs.formSimulacao.$emit('clickSubmit')
    },
    onSubmit() {
      if (!this.validarFormaEntrega()) return
      this.isLoading = true
      let formaEntrega = this.prepareOpcoes(this.form)
      if (this.mode == 'edit' && formaEntrega.idformaentrega) {
        this.$store
          .dispatch(FORMA_ENTREGA_UPDATE, formaEntrega)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Forma de entrega atualizada com sucesso'
            )
            this.onClose(true)
          })
          .catch(err => {
            const erro =
              err.response.data.Mensagem ||
              err.response.data ||
              'Erro ao atualizar forma de entrega'
            this.$vueOnToast.pop('error', erro)
            this.isLoading = false
          })
      } else {
        this.$store
          .dispatch(FORMA_ENTREGA_CREATE, formaEntrega)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Forma de entrega criada com sucesso'
            )
            this.onClose(true)
          })
          .catch(err => {
            const erro =
              err.response.data.Mensagem ||
              err.response.data ||
              'Erro ao criar forma de entrega'
            this.$vueOnToast.pop('error', erro)
            this.isLoading = false
          })
      }
    },
    getData(idformaentrega) {
      this.isLoading = true
      this.$store
        .dispatch(FORMA_ENTREGA_LOAD, idformaentrega)
        .then(data => {
          this.setFormaEntregaForm(data)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    getFornecedor() {
      this.isLoading = true
      this.$store
        .dispatch(FORMA_ENTREGA_FORNECEDOR)
        .then(data => {
          if (data) {
            const {
              taxa_coleta,
              taxaconveniencia,
              taxa_extra_por_metro,
              quantidade_delivery,
              quantidade_retirada,
              idareavendafornecedor,
              distancia_taxa_entrega_base,
              distancia_pedido_minimo_base,
              acrescimo_ped_min_por_metro,
              valormaxpedido,
              valorminpedido,
              valormaxpedido_retirada,
              valorminpedido_retirada
            } = data

            this.form = {
              ...this.form,
              taxaColeta: taxa_coleta,
              taxaConveniencia: taxaconveniencia,
              taxaExtraPorMetro: taxa_extra_por_metro * 1000 || 0,
              acrescimoPedMinimoPorKm: acrescimo_ped_min_por_metro * 1000 || 0,
              valormaxpedido: valormaxpedido || 999999,
              valorminpedido: valorminpedido || 0,
              valormaxpedido_retirada: valormaxpedido_retirada || 999999,
              valorminpedido_retirada: valorminpedido_retirada || 0
            }

            if (idareavendafornecedor) {
              this.form = {
                ...this.form,
                idareavendafornecedor: idareavendafornecedor,
                distanciaTaxaEntrega: distancia_taxa_entrega_base || 0,
                distanciaPedidoMinimo: distancia_pedido_minimo_base || 0
              }
            }

            this.form.showDelivery = Number(quantidade_delivery) == 0
            this.form.showRetirada = Number(quantidade_retirada) == 0
          }
          let { idformaentrega } = this.$route.params
          let { detalhes } = this.$route.query
          if (detalhes) {
            this.mode = 'detail'
          } else if (idformaentrega === 'novo') {
            this.mode = 'add'
          } else if (idformaentrega === 'simulacao') {
            this.mode = 'simulation'
          } else {
            this.mode = 'edit'
            this.getData(idformaentrega)
          }
          if (this.mode !== 'edit') {
            const getDefaultType = () => {
              if (this.form.showDelivery) return 'DELIVERY'
              if (this.form.showRetirada) return 'RETIRADA'
              return 'TRANSPORTE'
            }
            this.form.tipo = getDefaultType()
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getDetalhesCustoTransporte(idformaentrega) {
      this.isLoading = true
      const body = {
        columns: [
          {
            data: 'descricao_forma_transporte',
            name: '',
            orderable: 'true',
            search: { value: '', regex: 'false' },
            searchable: 'true'
          }
        ],
        draw: '1',
        length: '100',
        order: [
          {
            column: '0',
            dir: 'asc'
          }
        ],
        search: { value: '', regex: 'false' },
        start: '0'
      }
      this.$store
        .dispatch(FORMA_ENTREGA_FORMAS_LIST, { idformaentrega, body })
        .then(result => {
          const formas = (_.get(result, 'data') || []).map(d => ({
            descricao_forma_transporte: d.descricao_forma_transporte,
            cep_inicial: this.maskCep(d.cep_inicial),
            cep_final: this.maskCep(d.cep_final),
            custo_transporte: d.custo_transporte,
            custo_percentual_seguro: d.custo_percentual_seguro,
            peso_inicial: d.peso_inicial,
            peso_final: d.peso_final,
            quant_dias_preparo: d.quant_dias_preparo,
            quant_dias_entrega: d.quant_dias_entrega,
            total_pedido_inicial: d.total_pedido_inicial,
            total_pedido_final: d.total_pedido_final
          }))
          this.form.baseadaCliente.formas = formas
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getCalendarios() {
      this.isLoading = true
      this.$store
        .dispatch(FORMA_ENTREGA_CALENDARIO_LIST)
        .then(data => {
          this.calendarios = (data || []).map(v => {
            return { value: v.idcalendarioentrega, text: v.nome_calendario }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    updateValues(valores) {
      this.form = {
        ...this.form,
        ...valores
      }
    },
    setFormaEntregaForm(formaEntrega) {
      this.form.idformaentrega = formaEntrega.idformaentrega
      this.form.nome = formaEntrega.nomeforma
      this.form.nomeforma = formaEntrega.nomeforma
      this.form.erpTransportadora = formaEntrega.id_forma_entrega_erp
      this.form.idcalendarioentrega = formaEntrega.idcalendarioentrega
      this.form.permiteAgendamento = !!formaEntrega.idcalendarioentrega
      this.form.tipo = formaEntrega.tipo
      this.form.idfornecedor = formaEntrega.idfornecedor || this.getFornecedorId
      const credenciais = formaEntrega.credenciais || {}
      const opcoes = formaEntrega.opcoes_configuracao || {}

      if (formaEntrega.tipo === 'CORREIOS') {
        this.form.correios = {
          usuario: credenciais.usuario,
          senha: credenciais.senha,
          cartaoPostagem: credenciais.cartaoPostagem,
          cepOrigem: this.maskCep(opcoes.cepOrigem),
          metodoCalculo: opcoes.metodoCalculo || 'SOMA_CUSTO_POR_PRODUTO',
          valorDeclarado: opcoes.valorDeclarado || 'NAO_DECLARAR_VALOR',
          opcoes: {
            servicos: opcoes.servicos
          },
          acrescimoTaxa: opcoes.acrescimoTaxa,
          diasSeparacao: opcoes.diasSeparacao
        }
      } else if (formaEntrega.tipo === 'BASEADA_CLIENTE') {
        this.form.baseadaCliente = {
          formas: [],
          idsegmentocliente: opcoes.idsegmentocliente
        }
        this.getDetalhesCustoTransporte(this.form.idformaentrega)
      } else if (formaEntrega.tipo === 'FRENET') {
        this.form.frenet = {
          token: credenciais.token,
          cepOrigem: this.maskCep(opcoes.cepOrigem),
          diasSeparacao: opcoes.diasSeparacao,
          acrescimoTaxa: opcoes.acrescimoTaxa,
          transportadora: {
            idtransportadora: null,
            nome: '',
            codigo_hub: '',
            codigo_erp: ''
          },
          transportadoras: formaEntrega.transportadoras || [],
          permiteRestricoesFrenet:
            String(_.get(opcoes, 'permiteRestricoesFrenet', 'false')) === 'true'
        }
      } else {
        this.form.correios = {
          usuario: '',
          senha: '',
          cartaoPostagem: '',
          acrescimoTaxa: 0,
          diasSeparacao: 1,
          cepOrigem: '',
          opcoes: {
            servicos: {}
          }
        }
      }
    },
    prepareOpcoes(form) {
      if (form.tipo === 'TRANSPORTE' && this.mode == 'add') {
        const formData = new FormData()
        formData.append('nomeforma', form.nomeforma)
        formData.append('nome', form.nomeforma)
        formData.append('idfornecedor', this.getFornecedorId)
        formData.append('tipo', form.tipo)
        formData.append('forma-transporte-selecionada', form.tipo)
        formData.append('file', form.currentFile)
        formData.append('encoding', form.encoding)
        formData.append(
          'erpTransportadora',
          form.erpTransportadora ? form.erpTransportadora : ''
        )
        formData.append('permiteAgendamento', form.permiteAgendamento)
        formData.append(
          'idcalendarioentrega',
          form.idcalendarioentrega ? form.idcalendarioentrega : ''
        )
        return formData
      }
      const clearCep = cep => cep.replace(/\D+/g, '')
      const convertToKilometers = value => value / 1000
      this.form.taxaExtraPorMetro = convertToKilometers(
        this.form.taxaExtraPorMetro
      )
      this.form.acrescimoPedMinimoPorKm = convertToKilometers(
        this.form.acrescimoPedMinimoPorKm
      )
      this.form.taxaConveniencia = parseFloat(this.form.taxaConveniencia)
      this.form.taxaExtraPorMetro = parseFloat(this.form.taxaExtraPorMetro)
      this.form.acrescimoPedMinimoPorKm = parseFloat(
        this.form.acrescimoPedMinimoPorKm
      )
      this.form.valormaxpedido = parseFloat(this.form.valormaxpedido)
      this.form.valorminpedido = parseFloat(this.form.valorminpedido)
      this.form.distanciaTaxaEntrega = parseInt(this.form.distanciaTaxaEntrega)
      this.form.distanciaPedidoMinimo = parseInt(
        this.form.distanciaPedidoMinimo
      )
      this.form.nome = this.form.nomeforma
      this.form.idcalendarioentrega = this.form.permiteAgendamento
        ? this.form.idcalendarioentrega
        : null
      const commonConfig = {
        ...this.form,
        nome: form.nomeforma,
        opcoes_configuracao: {
          acrescimoTaxa: form.acrescimoTaxa || 0,
          diasSeparacao: form.diasSeparacao || 1
        }
      }
      if (form.tipo === 'CORREIOS') {
        return {
          ...commonConfig,
          correios: {
            cepOrigem: clearCep(this.form.correios.cepOrigem),
            acrescimoTaxa: form.correios.acrescimoTaxa,
            diasSeparacao: form.correios.diasSeparacao,
            metodoCalculo: form.correios.metodoCalculo,
            valorDeclarado: form.correios.valorDeclarado,
            usuario: form.correios.usuario,
            senha: form.correios.senha
          },
          credenciais: {
            usuario: form.correios.usuario,
            senha: form.correios.senha,
            cartaoPostagem: form.correios.cartaoPostagem
          },
          opcoes_configuracao: {
            servicos: form.correios.opcoes.servicos,
            metodoCalculo: form.correios.metodoCalculo,
            cepOrigem: form.correios.cepOrigem.replace(/\D+/g, ''),
            valorDeclarado: form.correios.valorDeclarado,
            acrescimoTaxa: form.correios.acrescimoTaxa,
            diasSeparacao: form.correios.diasSeparacao
          }
        }
      }
      if (form.tipo === 'BASEADA_CLIENTE') {
        this.form.baseadaCliente.formas.forEach(forma => {
          forma.cep_inicial = clearCep(forma.cep_inicial)
          forma.cep_final = clearCep(forma.cep_final)
        })
        return {
          ...commonConfig,
          opcoes_configuracao: {
            ...commonConfig.opcoes_configuracao,
            idsegmentocliente: form.baseadaCliente.idsegmentocliente || null
          }
        }
      }
      if (form.tipo === 'FRENET') {
        this.form.frenet.cepOrigem = clearCep(this.form.frenet.cepOrigem)
        return {
          ...commonConfig,
          credenciais: { token: form.frenet.token },
          opcoes_configuracao: {
            ...commonConfig.opcoes_configuracao,
            cepOrigem: form.frenet.cepOrigem.replace(/\D+/g, ''),
            acrescimoTaxa: form.frenet.acrescimoTaxa || 0,
            diasSeparacao: form.frenet.diasSeparacao || 1,
            permiteRestricoesFrenet:
              String(_.get(form.frenet, 'permiteRestricoesFrenet', 'false')) ===
              'true'
          }
        }
      }
      if (form.tipo === 'TRANSPORTE' && this.mode == 'add') {
        this.$vueOnToast.pop('info', 'Estamos processando os dados do arquivo.')
      }
      return form
    },
    validarFormaEntrega() {
      if (this.mode == 'detail') {
        return false
      }
      const errors = []
      this.valid = true
      const cepValidation = (cep, message) => {
        if (!cep || cep.length !== 8) {
          errors.push(message)
          this.valid = false
        }
      }
      if (!this.form.nomeforma) {
        errors.push('Informe o nome da forma de entrega')
        this.valid = false
      }
      if (this.form.permiteAgendamento && !this.form.idcalendarioentrega) {
        errors.push('Selecione o calendário')
        this.valid = false
      }
      if (this.form.tipo === 'FRENET') {
        const frenet = this.form.frenet
        if (frenet) {
          if (!frenet.token) {
            errors.push('Insira o token da API')
            this.valid = false
          }
          if (!frenet.cepOrigem) {
            errors.push('Insira o CEP de origem')
            this.valid = false
          }
          const cepFrenet = this.clearCep(frenet.cepOrigem)
          cepValidation(cepFrenet, 'CEP inválido para o Frenet')
        }
      }
      if (this.form.tipo === 'BASEADA_CLIENTE') {
        const baseadaCliente = this.form.baseadaCliente
        if (baseadaCliente) {
          if (!baseadaCliente.idsegmentocliente) {
            errors.push('Selecione o segmento do cliente')
            this.valid = false
          }
          if (!(baseadaCliente.formas || []).length) {
            errors.push('Insira pelo menos uma forma de entrega')
            this.valid = false
          }
          baseadaCliente.formas.forEach((forma, index) => {
            const cepInicial = this.clearCep(forma.cep_inicial)
            const cepFinal = this.clearCep(forma.cep_final)
            cepValidation(
              cepInicial,
              `CEP inicial inválido na forma ${index + 1}`
            )
            cepValidation(cepFinal, `CEP final inválido na forma ${index + 1}`)
          })
        }
      }
      if (this.form.tipo === 'CORREIOS') {
        const correios = this.form.correios
        if (correios) {
          const cepCorreios = this.clearCep(correios.cepOrigem)
          cepValidation(cepCorreios, 'CEP inválido para os Correios')
        }
      }
      if (this.form.tipo === 'DELIVERY' && this.form.valormaxpedido <= 0) {
        errors.push(
          'O valor máximo do pedido não pode ser 0 (zero) para entrega'
        )
        this.valid = false
      }
      if (
        this.form.tipo === 'RETIRADA' &&
        this.form.valormaxpedido_retirada <= 0
      ) {
        errors.push(
          'O valor máximo do pedido não pode ser 0 (zero) para retirada'
        )
        this.valid = false
      }
      if (!this.valid) {
        this.$vueOnToast.pop('error', errors.join(' - '))
      }

      return this.valid
    },
    maskCep(cep) {
      const cepAddress = String(cep).replace(/[^0-9-]/g, '')
      const numericCep = cepAddress.replace(/\D/g, '')
      const limitedCep = numericCep.substring(0, 8)
      return this.formatCep(limitedCep)
    },
    formatCep(limitedCep) {
      if (limitedCep.length >= 8) {
        const formattedCep = limitedCep.replace(/(\d{5})(\d{3})$/, '$1-$2')
        return formattedCep
      }
      return limitedCep
    },
    clearCep(cep) {
      return cep ? cep.replace('-', '') : ''
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `api/v4/forma-entrega/detalhes-custo-transporte/${this.form.idformaentrega}`
    },
    title() {
      return this.mode == 'edit'
        ? 'Editar forma de entrega'
        : 'Nova  forma de entrega'
    },
    formasEntregas() {
      if (this.mode == 'edit') {
        return this.formasEntrega
          .filter(fe => fe.value == this.form.tipo)
          .map(f => {
            return { ...f, show: true }
          })
      } else {
        return this.formasEntrega.map(forma => {
          let obj = {}
          if (forma.value === 'DELIVERY') {
            obj = {
              ...forma,
              show: this.form.showDelivery || this.mode == 'edit'
            }
          } else if (forma.value === 'RETIRADA') {
            obj = {
              ...forma,
              show: this.form.showRetirada || this.mode == 'edit'
            }
          } else {
            obj = { ...forma, show: true }
          }
          return obj
        })
      }
    }
  },
  created() {
    this.form.idformaentrega = this.$route.params.idformaentrega
    this.form.nomeforma = this.$route.query.nomeforma
    this.getFornecedor()
    this.getCalendarios()
  }
}
</script>
